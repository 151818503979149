import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/node_modules/.pnpm/next-intl@3.19.4_next@14.2.13_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1__react@18.3.1/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/node_modules/.pnpm/next-intl@3.19.4_next@14.2.13_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1__react@18.3.1/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/.pnpm/next@14.2.13_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/.pnpm/next@14.2.13_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"M_PLUS_1\",\"arguments\":[{\"subsets\":[\"latin\"],\"preload\":true}],\"variableName\":\"font\"}");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/app/[locale]/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderInner"] */ "/opt/buildhome/repo/src/components/header/HeaderInner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderMobileNav"] */ "/opt/buildhome/repo/src/components/header/HeaderMobileNav.tsx");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/components/LangSelect.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Logo"] */ "/opt/buildhome/repo/src/components/Logo.tsx");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/components/NavItem.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/opt/buildhome/repo/src/components/toast/Toaster.tsx");
